import { gql } from "@apollo/client";

export const MAKE_ORDER = gql`
  mutation makeOrder($input: OrderInput!) {
    makeOrder(data: $input) {
      data {
        id
        attributes {
          visitDate
          payType
          name
          surname
          patronymic
          iin
          phone
          homeVisit
          cost
          fullAddress
          number
        }
      }
    }
  }
`;
