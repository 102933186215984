import * as React from "react";
import Layout, { AppStateContext } from "../components/Layout/Layout";
import parse from "html-react-parser";
//@ts-ignore
import Calendar from "../images/calendar.svg";
//@ts-ignore
import Arrow from "../images/arrowDown_grey.svg";
import { injectIntl } from "gatsby-plugin-intl";
import BreadCrumbs from "../BreadCrumbs";
import "./application-form.css";
import { useEffect, useRef, useState } from "react";
import {
  localStorageGetBasket,
  localStorageResetBasket,
} from "../service/storage";
import { useMutation, useQuery } from "@apollo/client";
import { GET_ANALYSIS_BY_TYPE } from "../query/getAnalysis";
import { moneyFormatFromNumber } from "../utils/formatter";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { MAKE_ORDER } from "../mutation/makeOrder";
import DatePickerField from "../components/DatePickerField";
import { navigate } from "gatsby-plugin-intl";

interface Values {
  name: string; // Имя
  surname: string; // Фамилия
  patronymic: string; // Отчество
  iin: string; // ИИН
  phone: string; //  +7 XXX XXXXXXX
  // payType: string; // cash, online
  date: string; // 01/01/2022
  time: string; // 12:00
}
//@ts-ignore
const basket = ({ intl, pageContext }) => {
  // TODO: rename page name to application form
  // TODO: disallow access to page on empty basket or redirect to basket
  const localBasket = useRef(localStorageGetBasket());
  const localResetBasket = useRef(localStorageResetBasket);

  // @ts-ignore
  const [analyses, setAnalyses] = useState([]);
  const [totalCost, setTotalCost] = useState(0);
  const [disable, setDisable] = useState(true);
  const [checked, setChecked] = useState(false);
  const [makeOrder, { loading }] = useMutation(MAKE_ORDER);

  const makeEnable = () => {
    setChecked(!checked);
    if (!checked) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  };

  if (localBasket.current.analyses.length > 0) {
    const variables = {
      filters: {
        id: {
          in: localBasket.current.analyses,
        },
      },
      pagination: {
        pageSize: 20,
      },
      locale: intl.locale,
    };

    const { loading, error } = useQuery(GET_ANALYSIS_BY_TYPE, {
      variables,
      onCompleted: (data) => {
        setAnalyses(data.analyses.data);
        setTotalCost(
          data.analyses.data.reduce(
            (acc: any, analysis: any) => acc + analysis.attributes.price,
            0
          )
        );
      },
    });

    if (loading) return "Loading...";

    if (error) return `Error! ${error.message}`;
  }

  const prepareData = (values: Values) => {
    const visitDate = new Date(
      values.date.getTime() +
        values.time.getHours() * 60 * 60 * 1000 +
        values.time.getMinutes() * 60 * 1000
    ).toISOString();
    const preparedData = {
      name: values.name,
      surname: values.surname,
      patronymic: values.patronymic,
      iin: values.iin,
      phone: values.phone.replace(/\D/g, ""),
      payType: "nalichnye",
      visitDate: visitDate,
      analyses: analyses.map(({ id }) => id),
      homeVisit: false,
    };

    return preparedData;
  };

  return (
    <Layout>
      <div className="flex items-center justify-center bg-[#FAFAFA] ">
        <div className="mb-[10rem] flex flex-col  xl:max-w-[1200px] xl:w-[1200px] lg:max-w-[1000px] lg:w-[1000px] sm:max-w-[680px] sm:w-[680px] xs:max-w-[300px] xs:w-[300px]">
          <div className="ml-[0.2rem] mt-[2rem] mb-[2rem] ">
            <BreadCrumbs
              pageContext={pageContext}
              title="ApplicationForm.Title"
            />
          </div>
          <h2 className=" text-[2rem] font-semibold text-[#001439] ">
            {parse(intl.formatMessage({ id: "ApplicationForm.Title" }))}
          </h2>
          <AppStateContext.Consumer>
            {({ profile }) => (
              <Formik
                initialValues={{
                  name: (profile && profile.name) || "",
                  surname: (profile && profile.surname) || "",
                  patronymic: (profile && profile.patronymic) || "",
                  iin: (profile && profile.iin) || "",
                  phone: (profile && profile.username) || "",
                  // payType: '',
                  date: "",
                  time: "",
                }}
                onSubmit={(
                  values: Values,
                  { setSubmitting }: FormikHelpers<Values>
                ) => {
                  makeOrder({
                    variables: {
                      input: prepareData(values),
                    },
                    onCompleted: (data) => {
                      setSubmitting(false);
                      localResetBasket.current();
                      setAnalyses([]);
                      navigate(
                        `/personal/history/${data.makeOrder.data.attributes.number}`
                      );
                    },
                    onError: (error) => {
                      console.error(error.message);
                    },
                  });
                }}
              >
                <Form>
                  <div className="flex flex-col xs:flex-col bg-white rounded-[1rem] w-auto pt-[2rem] lg:pl-[1.5rem] xs:pl-[1.2rem] pb-[2.5rem]  mt-[2rem]">
                    {/*Способ оплаты*/}
                    {/*<fieldset className="flex sm:flex-row xs:flex-col gap-4">*/}
                    {/*  <div className="flex gap-4 mt-[1rem] sm:mb-[1.5rem]  items-center">*/}
                    {/*    <input*/}
                    {/*      id="me"*/}
                    {/*      className="application_checkbox peer/me w-[1.25rem] h-[1.25rem] border border-[#001439] border-opacity-25 text-indigo-600"*/}
                    {/*      type="radio"*/}
                    {/*      name="status"*/}
                    {/*      checked*/}
                    {/*    />*/}
                    {/*    <label htmlFor="me" className="peer-checked/me">*/}
                    {/*      <p className="font-medium text-[1rem]">*/}
                    {/*        {parse(*/}
                    {/*          intl.formatMessage({*/}
                    {/*            id: "ApplicationForm.RegisterFor.Me",*/}
                    {/*          })*/}
                    {/*        )}*/}
                    {/*      </p>*/}
                    {/*    </label>*/}
                    {/*  </div>*/}
                    {/*  <div className="flex gap-4 sm:mt-[1rem] mb-[1.5rem] items-center">*/}
                    {/*    <input*/}
                    {/*      id="familyMember"*/}
                    {/*      className="peer/familyMember w-[1.25rem] h-[1.25rem] border border-[#001439] border-opacity-25 text-indigo-600"*/}
                    {/*      type="radio"*/}
                    {/*      name="status"*/}
                    {/*    />*/}
                    {/*    <label*/}
                    {/*      htmlFor="familyMember"*/}
                    {/*      className="peer-checked/familyMember:text-indigo-600"*/}
                    {/*    >*/}
                    {/*      <p className="font-medium text-[1rem]">*/}
                    {/*        {parse(*/}
                    {/*          intl.formatMessage({*/}
                    {/*            id: "ApplicationForm.RegisterFor.FamilyMember",*/}
                    {/*          })*/}
                    {/*        )}*/}
                    {/*      </p>*/}
                    {/*    </label>*/}
                    {/*  </div>*/}
                    {/*</fieldset>*/}
                    {/*Фамилия * Имя * Отчество */}
                    <div className="flex flex-wrap  ">
                      <div className="grid grid-flow-row ">
                        <p className="font-medium text-[1rem]">
                          {parse(
                            intl.formatMessage({
                              id: "Registration.Form.Surname",
                            })
                          )}
                          <span className="text-red-600">*</span>
                        </p>
                        <Field
                          type="text"
                          id="surname"
                          name="surname"
                          placeholder={parse(
                            intl.formatMessage({
                              id: "Registration.Form.Surname",
                            })
                          )}
                          className=" pl-[1rem] px-[0.8rem] mt-[0.875rem] lg:w-[20rem] sm:w-[17rem] xs:w-[16.5rem] h-[3rem] border rounded-[0.5rem] "
                        />
                      </div>
                      <div className="sm:mt-[0rem] xs:mt-[2rem] grid grid-flow-row sm:pl-[2rem] sm:mt-[0rem] xs:mt-[1rem]">
                        <p className="font-medium text-[1rem]">
                          {parse(
                            intl.formatMessage({ id: "Registration.Form.Name" })
                          )}{" "}
                          <span className="text-red-600">*</span>
                        </p>
                        <Field
                          type="text"
                          id="name"
                          name="name"
                          placeholder={parse(
                            intl.formatMessage({ id: "Registration.Form.Name" })
                          )}
                          className=" pl-[1rem] px-[0.8rem] mt-[0.875rem] lg:w-[20rem] sm:w-[17rem] xs:w-[16.5rem] h-[3rem] border rounded-[0.5rem]"
                        />
                      </div>
                      <div className="sm:mt-[0rem] xs:mt-[2rem] grid grid-flow-row xl:pl-[2rem] xl:mt-[0rem] xs:mt-[1rem]">
                        <p className="font-medium text-[1rem]">
                          {parse(
                            intl.formatMessage({
                              id: "Registration.Form.MiddleName",
                            })
                          )}{" "}
                          {/*<span className="text-red-600">*</span>*/}
                        </p>
                        <Field
                          type="text"
                          id="patronymic"
                          name="patronymic"
                          placeholder={parse(
                            intl.formatMessage({
                              id: "Registration.Form.MiddleName",
                            })
                          )}
                          className=" pl-[1rem] px-[0.8rem] mt-[0.875rem] lg:w-[20rem] sm:w-[17rem] xs:w-[16.5rem] h-[3rem] border rounded-[0.5rem]"
                        />
                      </div>
                    </div>
                    {/*Ваш ИИН * Номер телефона */}
                    <div className="mt-[2rem] flex flex-wrap  ">
                      <div className=" grid grid-flow-row ">
                        <p className="font-medium text-[1rem]">
                          {parse(
                            intl.formatMessage({ id: "Registration.Form.IIN" })
                          )}{" "}
                          <span className="text-red-600">*</span>
                        </p>
                        <Field
                          type="text"
                          id="iin"
                          name="iin"
                          placeholder={parse(
                            intl.formatMessage({ id: "Registration.Form.IIN" })
                          )}
                          className=" pl-[1rem] px-[0.8rem] mt-[0.875rem] lg:w-[20rem] sm:w-[17rem] xs:w-[16.5rem] h-[3rem] border rounded-[0.5rem]"
                        />
                      </div>
                      <div className="sm:mt-[0rem] xs:mt-[2rem] grid grid-flow-row sm:pl-[2rem] sm:mt-[0rem] xs:mt-[1rem]">
                        <p className="font-medium text-[1rem]">
                          {parse(
                            intl.formatMessage({
                              id: "Registration.Form.PhoneNumber",
                            })
                          )}{" "}
                          <span className="text-red-600">*</span>
                        </p>
                        <Field
                          type="text"
                          id="phone"
                          name="phone"
                          placeholder=" +7 XXX XXXXXXX"
                          className=" pl-[1rem] px-[0.8rem] mt-[0.875rem] lg:w-[20rem] sm:w-[17rem] xs:w-[16.5rem] h-[3rem] border rounded-[0.5rem]"
                        />
                      </div>
                    </div>
                    {/*Дата выезда * Время выезда*/}
                    <div className="mt-[2rem] flex flex-wrap ">
                      <div className="grid grid-flow-row ">
                        <p className="font-medium text-[1rem]">
                          {parse(
                            intl.formatMessage({ id: "ApplicationForm.Date" })
                          )}
                          <span className="text-red-600 ml-[0.2rem]">*</span>
                        </p>
                        <DatePickerField
                          name="date"
                          className=" pl-[1rem] px-[0.8rem] mt-[0.875rem] w-[15rem] h-[3rem] border rounded-[0.5rem]"
                          minDate={new Date()}
                        />
                        <img
                          src={Calendar}
                          alt=""
                          className="application_calendar cursor-pointer"
                        />
                      </div>
                      <div className="sm:mt-[0rem] xs:mt-[2rem] grid-flow-row sm:pl-[2rem] sm:mt-[0rem] xs:mt-[0rem]">
                        <p className="font-medium text-[1rem]">
                          {parse(
                            intl.formatMessage({ id: "ApplicationForm.Time" })
                          )}
                          <span className="text-red-600 ml-[0.2rem]">*</span>
                        </p>
                        <DatePickerField
                          name="time"
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          timeFormat="HH:mm"
                          dateFormat="HH:mm"
                          className=" pl-[1rem] px-[0.8rem] mt-[0.875rem] w-[15rem] h-[3rem] border rounded-[0.5rem]"
                        />
                        <img
                          src={Arrow}
                          alt=""
                          className="arrow cursor-pointer"
                        />
                      </div>
                    </div>
                    {/*Способ оплаты*/}
                    {/*<fieldset className="flex gap-4">*/}
                    {/*  <legend className="my-[2rem]">*/}
                    {/*    <h4 className="mt-[2.75rem] font-semibold text-[1.25rem]">*/}
                    {/*      {parse(*/}
                    {/*        intl.formatMessage({*/}
                    {/*          id: "ApplicationForm.PaymentType.Title",*/}
                    {/*        })*/}
                    {/*      )}*/}
                    {/*    </h4>*/}
                    {/*  </legend>*/}
                    {/*  <div className="flex gap-4 my-[2rem]  items-center">*/}
                    {/*    <Field type="radio" id="online" name="payType" value="online" className="application_checkbox peer/online w-[1.25rem] h-[1.25rem] border border-[#001439] border-opacity-25 text-indigo-600"/>*/}
                    {/*    <label htmlFor="online" className="peer-checked/online">*/}
                    {/*      <p className="font-medium text-[1rem]">*/}
                    {/*        {parse(*/}
                    {/*          intl.formatMessage({*/}
                    {/*            id: "ApplicationForm.PaymentType.Online",*/}
                    {/*          })*/}
                    {/*        )}*/}
                    {/*      </p>*/}
                    {/*    </label>*/}
                    {/*  </div>*/}
                    {/*  <div className="flex gap-4 my-[2rem] items-center">*/}
                    {/*    <Field type="radio" id="cash" name="payType" value="cash" className="peer/cash w-[1.25rem] h-[1.25rem] border border-[#001439] border-opacity-25 text-indigo-600"/>*/}
                    {/*    <label*/}
                    {/*      htmlFor="cash"*/}
                    {/*      className="peer-checked/cash:text-indigo-600"*/}
                    {/*    >*/}
                    {/*      <p className="font-medium text-[1rem]">*/}
                    {/*        {parse(*/}
                    {/*          intl.formatMessage({*/}
                    {/*            id: "ApplicationForm.PaymentType.Cash",*/}
                    {/*          })*/}
                    {/*        )}*/}
                    {/*      </p>*/}
                    {/*    </label>*/}
                    {/*  </div>*/}
                    {/*</fieldset>*/}
                    {/*Agreement*/}
                    <div className="mt-[2rem] flex sm:w-[100%] xs:w-[90%] items-center    sm:gap-6 xs:gap-3 pr-[0.3rem]">
                      <input
                        type="checkbox"
                        className="checkbox_application"
                        onClick={makeEnable}
                      />
                      <p className="font-medium text-[1rem] cursor-pointer ">
                        {parse(
                          intl.formatMessage({
                            id: "Registration.Form.Agreement",
                          })
                        )}
                      </p>
                    </div>

                    <div className="flex sm:flex-row xs:flex-col">
                      <h2 className="mt-[2rem] font-semibold text-[1.75rem] text-[#001439]">
                        {intl.formatMessage({
                          id: "ServiceMenu.HouseCall.Total",
                        })}
                      </h2>
                      <h2 className="mt-[2rem] sm:ml-[0.8rem] font-semibold text-[1.75rem] text-[#001439]">
                        {moneyFormatFromNumber(totalCost)} ₸
                      </h2>
                    </div>
                  </div>

                  <button
                    type="submit"
                    className={
                      "mt-[2rem] w-[13.8rem] h-[3rem] text-[1.25rem] rounded-[0.56rem] text-white font-semibold " +
                      " " +
                      `${disable ? "bg-sky-500/50" : "bg-[#618EE4]"}`
                    }
                    disabled={disable}
                  >
                    {parse(
                      intl.formatMessage({ id: "ApplicationForm.Button" })
                    )}
                  </button>
                </Form>
              </Formik>
            )}
          </AppStateContext.Consumer>
        </div>
      </div>
    </Layout>
  );
};

export default injectIntl(basket);
